<template>
  <div class="side-menu-row">
    <div class="side-menu-row__text" ref="text" @click="navigate"><slot /></div>
    <div class="side-menu-row__underline" :style="{ width: underlineWidth + 'px' }"></div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    route: String
  },

  data() {
    return {
      underlineWidth: 0
    }
  },

  methods: {
    calcUnderlineWidth(path) {
      if (path !== this.route) this.underlineWidth = 0
      else this.underlineWidth = this.$refs.text.offsetWidth
    },

    navigate() {
      this.$store.state.sideMenuOpened = false
      this.$router.push(this.route)
    }
  },

  watch: {
    '$route.path': {
      handler: function (val) {
        this.calcUnderlineWidth(val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/colors.scss';
.side-menu-row {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.11em;
  margin: 24px 0;
  cursor: pointer;

  &__text {
    display: inline;
  }

  &__underline {
    position: absolute;
    right: 0;
    margin-top: 3px;
    height: 3px;
    background-color: $tulluColor;
    transition: width 0.3s ease;
  }
}
</style>
