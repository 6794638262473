import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      sideMenuOpened: false
    }
  },
  mutations: {
    toggleSideMenu(state) {
      state.sideMenuOpened = !state.sideMenuOpened
    }
  }
})

export default store
