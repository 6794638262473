<template>
  <div>
    <div class="burger" :class="{ 'burger--active': active }">
      <div class="burger__line burger__line--1"></div>
      <div class="burger__line burger__line--2"></div>
      <div class="burger__line burger__line--3"></div>
      <div class="burger__line burger__line--4"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors.scss';

$burgerWidth: 25px;
$burgerHeight: 18px;
$burgerLineHeight: 2px;

.burger {
  position: relative;
  width: $burgerWidth;
  height: $burgerHeight;
  overflow: hidden;
  cursor: pointer;

  &__line {
    position: absolute;
    width: 100%;
    height: $burgerLineHeight;
    background-color: $ghostWhite;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    will-change: transform, opacity;

    &--1 {
      top: 0;
    }

    &--2 {
      top: calc(calc(#{$burgerHeight} / 2) - calc(#{$burgerLineHeight} / 2));
    }

    &--3 {
      top: calc(calc(#{$burgerHeight} / 2) - calc(#{$burgerLineHeight} / 2));
    }

    &--4 {
      bottom: 0;
    }
  }

  &--active {
    .burger__line {
      &--1 {
        transform: translateX(-$burgerWidth);
        opacity: 0;
      }

      &--2 {
        transform: rotate(45deg);
      }

      &--3 {
        transform: rotate(135deg);
      }

      &--4 {
        transform: translateX($burgerWidth);
        opacity: 0;
      }
    }
  }
}
</style>
