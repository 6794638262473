<template>
  <div class="app">
    <BurgerButton
      v-if="false"
      class="app__burger-button"
      :active="$store.state.sideMenuOpened"
      @click="$store.commit('toggleSideMenu')"
    ></BurgerButton>
    <SideMenu v-if="false" class="app__side-menu"></SideMenu>
    <div
      class="app__router-view"
      :class="{ 'app__router-view--opened': $store.state.sideMenuOpened }"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
import BurgerButton from './components/BurgerButton.vue'
import SideMenu from './components/SideMenu/SideMenu.vue'
export default {
  components: {
    BurgerButton,
    SideMenu
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
  }
}
</script>

<style lang="scss">
@import "./styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&display=swap");

body {
  font-family: "Lato", sans-serif;
  color: $ghostWhite;
  margin: 0;
  background-color: $backgroundDark;
  overflow: hidden;
  max-width: 100vw;
}

.app {
  position: relative;

  &__burger-button {
    position: absolute;
    right: 40px;
    top: 31px;
    z-index: 10;
  }

  &__router-view {
    transition: transform 0.25s linear;
    will-change: transform;

    &--opened {
      transform: perspective(110vw) translateX(-4vw) rotateY(25deg) scale(0.8);
    }
  }

  &__side-menu {
    position: absolute;
    right: 0;
    width: 20vw;
    height: 68vh;
    top: 16vh;
  }
}

@media only screen and (max-device-width: 640px) {
  .app {
    overflow-x: hidden;

    &__burger-button {
      top: 23px;
      right: 20px;
    }

    &__router-view {
      &--opened {
        transform: perspective(110vw) translateX(-17vw) rotateY(60deg)
          scale(0.7);
      }
    }
  }
}
</style>
