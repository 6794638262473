import * as Vue from 'vue'
import { createLangRouter, i18n } from 'vue-lang-router'
import { createWebHistory } from 'vue-router'
import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  }
  // {
  //   path: '/status',
  //   name: 'AppStatus',
  //   component: () => import('../views/AppStatus.vue')
  // },
  // {
  //   path: '/business',
  //   name: 'Business',
  //   component: () => import('../views/Business.vue')
  // },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: () => import('../views/Contact.vue')
  // },
  // {
  //   path: '/read/privacy',
  //   name: 'PrivacyPolicy',
  //   component: () => import('../views/PrivacyPolicy.vue')
  // },
  // {
  //   path: '/read/tos',
  //   name: 'TermsOfService',
  //   component: () => import('../views/TermsOfService.vue')
  // }
]

const langRouterOptions = {
  defaultLanguage: 'en',
  translations,
  localizedURLs
}

const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
}

const router = createLangRouter(langRouterOptions, routerOptions)

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || i18n.global.t('default.title')
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || i18n.global.t('default.description'))
  })
})

export default router
