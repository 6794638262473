export default {
  en: {
    name: 'English',
    load: () => { return import('./en.json') }
  },
  pl: {
    name: 'Polski',
    load: () => { return import('./pl.json') }
  }
}
