<template>
  <div class="side-menu">
    <div class="side-menu__rows">
      <SideMenuRow
        v-for="row in rows"
        :key="row.name"
        class="side-menu__row"
        :route="row.route"
        :ref="addRowRef"
        >{{ row.name }}</SideMenuRow
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { delay } from '../../scripts/helpers.js'
import SideMenuRow from './SideMenuRow.vue'

export default {
  data() {
    return {
      rows: [
        {
          name: 'Hello',
          route: '/'
        },
        {
          name: 'App Status',
          route: '/status'
        },
        {
          name: 'Contact',
          route: '/contact'
        },
        {
          name: 'Tullu Business',
          route: '/business'
        },
        {
          name: 'Terms of Use',
          route: '/read/tos'
        },
        {
          name: 'Privacy Policy',
          route: '/read/privacy'
        }
      ],
      rowsRefs: []
    }
  },

  components: {
    SideMenuRow
  },

  methods: {
    addRowRef(r) {
      this.rowsRefs.push(r.$el)
    },

    async animateRowsShow() {
      for (const row of this.rowsRefs) {
        row.classList.add('side-menu__row--visible')
        await delay(70)
      }
    },

    async animateRowsHide() {
      for (const row of this.rowsRefs) {
        row.classList.remove('side-menu__row--visible')
        await delay(70)
      }
    }
  },

  computed: mapState(['sideMenuOpened']),

  watch: {
    sideMenuOpened(n, o) {
      if (n) {
        this.animateRowsShow()
      } else {
        this.animateRowsHide()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/colors.scss';
.side-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__rows {
    text-align: right;
    padding-right: 35px;
  }

  &__row {
    transition: transform 0.3s ease-in-out, opacity 0.8s ease-in-out;
    will-change: transform, opacity;
    transform: translateX(30vw);
    opacity: 0;

    &--visible {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

@media only screen and (max-device-width: 640px) {
  .side-menu {
    width: 40vw;
  }
}
</style>
